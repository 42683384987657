import Enquiry from "./Enquiry";
import { useState } from 'react';
import {
    GoogleReCaptcha,
} from 'react-google-recaptcha-v3';
// import GuidedWorkflow from "./guidedWorkflow/guidedWorkflow";

export default function EnquiryForm(props: any) {
    const [captchaValue, setcaptchaValue] = useState<any>('');

    function onCpatchaChange(value: string | null): void {
        setcaptchaValue(value);
    }

    function handleRefreshCaptcha() {
        setcaptchaValue('');
    }

    return (
        <>
            {
                captchaValue !== '' ? <Enquiry captchaToken={captchaValue} refreshCaptcha={handleRefreshCaptcha}></Enquiry> : <div
                    className='spinnerText'
                    style={{
                        marginTop: '50px',
                    }}
                >
                    <div _ngcontent-c3='' className='spinner'></div>
                </div>
            }


            <GoogleReCaptcha
                onVerify={(value) => {
                    if (!captchaValue) {
                        onCpatchaChange(value);
                    }
                }}
            />


            {/* <GuidedWorkflow /> */}
        </>
    )
}
