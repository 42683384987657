import axios from 'axios';
const savedAccessToken = sessionStorage.getItem('accessToken');

const adminList = ['mbhallam', 'rpisal', 'usevekar', 'lkemerov', 'johancas', 'dpuhar', 'sizala', 'aratikum', 'bhagjosh'];

export const getSplunkKey = () => {
  return 'b959f9fc-dc49-4cb5-bd9e-710945652498';
};
export const getSplunkUrl = () => {
  return `https://web-help.cisco.com/splunk`;
};

export const apiUrl = () => {
  if (
    window.location.origin === 'https://web-help-dev.cisco.com' ||
    window.location.origin === 'http://localhost:3000'
  ) {
    return 'https://web-help-api-dev.cisco.com/';
  }
  if (window.location.origin === 'https://web-help-stg.cisco.com') {
    return 'https://web-help-api-stage.cisco.com/';
  } else if (window.location.origin === 'https://web-help.cisco.com') {
    return 'https://web-help.cisco.com/api/';
  }
};

export const getAccessLevel = () => {
  let userDetails = sessionStorage.getItem('UsersDetails');
  if (userDetails !== null) {
    let userAccessLevel = JSON.parse(userDetails)['access_level'];
    return userAccessLevel;
  } else {
    return 2;
  }
}

export const checkIsAdmin = () => {
  let userDetails = sessionStorage.getItem('UsersDetails');
  if (userDetails !== null) {
    let userID = JSON.parse(userDetails)['ccoid'];
    adminList.includes(userID)
  } else {
    return false;
  }
}

export const getFaqs = () => {
  let userDetails = sessionStorage.getItem('UsersDetails');
  if (userDetails !== null) {
    let userAccessLevel = JSON.parse(userDetails)['access_level'];
    let userID = JSON.parse(userDetails)['ccoid'];
    let data = {
      "access_level": userAccessLevel,
      "isAdmin": adminList.includes(userID)
    };
    return axios.post(`${apiUrl()}faq`, data, {
      headers: {
        Authorization: `Bearer ${savedAccessToken}`,
      }
    });

  } else {
    let data = {
      "access_level": 2,
      "isAdmin": true
    };
    return axios.post(`${apiUrl()}faq`, data, {
      headers: {
        Authorization: `Bearer ${savedAccessToken}`,
      }
    });

  }
  /* eturn axios.get(`${apiUrl()}faq`, {
    headers: {
      Authorization: `Bearer ${savedAccessToken}`,
    },
  }); */
};

export default async function getSearchedFaqs(name?: string) {
  //No serach of FAQ from here, will discard soon
  if (name) {
    return await axios.get(`${apiUrl()}faq?search=${name}`, {
      headers: {
        Authorization: `Bearer ${savedAccessToken}`,
      },
    });
  }
}

export const getLoginUrl = (param?: any) => {
  return axios.get(`${apiUrl()}okta/login?refer=${param}`);
};

export const getPopularFaqs = () => {
  return axios.get(`${apiUrl()}popular_faqs`, {
    headers: {
      Authorization: `Bearer ${savedAccessToken}`,
    },
  });
};

export const sendEnqueryEmail = (values: any, captchaToken: any, captchaVersion: any, isauthorized: any) => {
  if (values['issueSubCategory'] === "Add Contract to User" || values['issueSubCategory'] === "Remove Contract from User") {
    return axios.post(
      `${apiUrl()}send_email?firstname=${values.firstName}&lastname=${values.lastName
      }&email_address=${values.email}&issue_category=${values.issueCategory
      }&additional_email=${values.additionalEmailList.toString()}&issue_subcategory=${values.issueSubCategory}&description=${values.description
      }&contract_numbers=${values.contractNumbers}&user_email_address=${values.userEmailAddresses}&isauthorized=${isauthorizedFn(isauthorized)}&captcha_token=${captchaToken}&captcha_version=${captchaVersion}`
    );
  } else if (values['issueSubCategory'] === "Password Reset and Recovery" || values['issueSubCategory'] === "Account Login Error Messages") {
    return axios.post(
      `${apiUrl()}send_email?firstname=${values.firstName}&lastname=${values.lastName
      }&email_address=${values.email}&issue_category=${values.issueCategory
      }&additional_email=${values.additionalEmailList.toString()}&issue_subcategory=${values.issueSubCategory}&description=${values.description
      }&impacted_user=${values.impactedUser}&isauthorized=${isauthorizedFn(isauthorized)}&captcha_token=${captchaToken}&captcha_version=${captchaVersion}`
    );
  } else if (values['issueSubCategory'] === "Access to Download Software Image") {
    if (values['softwareDownloadUrl'] === undefined || values['softwareDownloadUrl'] === '') {
      values['softwareDownloadUrl'] = 'NA';
    }
    return axios.post(
      `${apiUrl()}send_email?firstname=${values.firstName}&lastname=${values.lastName
      }&email_address=${values.email}&issue_category=${values.issueCategory
      }&additional_email=${values.additionalEmailList.toString()}&issue_subcategory=${values.issueSubCategory}&description=${values.description
      }&software_download_url=${values.softwareDownloadUrl}&isauthorized=${isauthorizedFn(isauthorized)}&captcha_token=${captchaToken}&captcha_version=${captchaVersion}`
    );
  } else {
    return axios.post(
      `${apiUrl()}send_email?firstname=${values.firstName}&lastname=${values.lastName
      }&email_address=${values.email}&issue_category=${values.issueCategory
      }&additional_email=${values.additionalEmailList.toString()}&issue_subcategory=${values.issueSubCategory}&description=${values.description
      }&isauthorized=${isauthorizedFn(isauthorized)}&captcha_token=${captchaToken}&captcha_version=${captchaVersion}`
    );
  };
}

const isauthorizedFn = (isauthorized: any): boolean => {
  let userIsLoggedIn = sessionStorage.getItem('accessToken');
  // If the user is logged in, check if they are verified user
  if (userIsLoggedIn && isauthorized !== 'verified') {
    return false;
  }

  // If not logged in, allow access only if 'verified'
  return !!userIsLoggedIn || isauthorized === 'verified';
};

export const validateAccessToken = () => {
  let savedAccessToken = sessionStorage.getItem('accessToken');
  if (savedAccessToken) {
    return axios.get(`${apiUrl()}okta/token_validate`, {
      headers: {
        Authorization: `Bearer ${savedAccessToken}`,
      },
    });
  } else {
    return 'NO_ACCESS_TOKEN';
  }
};

export const updateFaqCount = (qId: any) => {
  if (qId) {
    return axios.put(`${apiUrl()}update_faq_count/${qId}`);
  }
};

export const logoutPortal = (email: any) => {
  let userIsLoggedIn = sessionStorage.getItem('accessToken');
  if (email) {
    return axios.get(`${apiUrl()}logout?email_id=${email}`, {
      headers: {
        Authorization: `Bearer ${userIsLoggedIn}`,
      },
    });
  }
};

export const getSearched = async (name?: string): Promise<any> => {
  if (name) {
    return await axios.get(`${apiUrl()}search?query=${name}`, {
      headers: {
        Authorization: `Bearer ${savedAccessToken}`,
      },
    });
  }
};

export const verify_email = (params: any) => {
  if (params) {
    return axios.post(`${apiUrl()}verify_email?to_email=${params.email_address}&otp=${params.otp}`);
  }
};

export const authorize_email = (params: any) => {
  if (params) {
    return axios.post(`${apiUrl()}authorize_email?to_email=${params.email_address}`);
  }
};

export const getResources = () => {
  let userDetails = sessionStorage.getItem('UsersDetails');
  if (userDetails !== null) {
    let userAccessLevel = JSON.parse(userDetails)['access_level'];
    let userID = JSON.parse(userDetails)['ccoid'];
    let data = {
      "access_level": userAccessLevel,
      "isAdmin": adminList.includes(userID)
    };
    return axios.post(`${apiUrl()}resources`, data, {
      headers: {
        Authorization: `Bearer ${savedAccessToken}`,
      }
    });

  } else {
    let data = {
      "access_level": 2,
      "isAdmin": false
    };
    return axios.post(`${apiUrl()}resources`, data, {
      headers: {
        Authorization: `Bearer ${savedAccessToken}`,
      }
    });

  }
  /* return axios.get(`${apiUrl()}resources`, {
    headers: {
      Authorization: `Bearer ${savedAccessToken}`,
    },
  }); */
};

export const update_resources_faq = (payload: any) => {
  if (payload.resources.length > 0) {
    payload.resources.map((item: any) => {
      item.id = String(item.id);
    })
  }
  if (payload) {
    return axios.post(`${apiUrl()}update_resources_faq`, payload, {
      headers: {
        Authorization: `Bearer ${savedAccessToken}`,
      }
    });
  }
};