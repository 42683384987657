import React, { useEffect, useState } from 'react';
import '../assets/css/general.css';
import Resource from './Resource';
import FaqSection from './FaqSection/FaqSection';
import Search from './Search';
import { apiUrl, getResources, getFaqs, update_resources_faq, validateAccessToken } from '../services/commonService';
import { Helmet } from 'react-helmet';
import ResourceEditView from './ResourcesEditView';
import FaqEdit from './FaqSection/FaqEdit';
const adminList = ['mbhallam', 'rpisal', 'usevekar', 'lkemerov', 'johancas', 'dpuhar', 'sizala', 'aratikum', 'bhagjosh'];

export default function Main() {
  const [isEditing, setIsEditing] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [columns, setColumns] = useState([]);
  const [isResourcesModified, setIsResourcesModified] = useState(false);
  const [loading, setLoading] = useState(true);
  const [allFaqs, setAllFaqs] = useState<any>({});
  const [updatedFaqs, setUpdatedFaqs] = useState<any>([]);

  useEffect(() => {
    getResourcesValue();
    getAllFAQs();
    setTimeout(() => validateToken(), 1000);
  }, []);

  const getResourcesValue = async () => {
    setLoading(true);

    try {
      const getResourcesData = await getResources();
      if (getResourcesData.data.Resources.length > 0) {
        setLoading(false);
        setColumns(getResourcesData.data.Resources);
      }
    } catch (error) {
      setLoading(false);
      //console.error('Failed to fetch resources data:', error);
    }
  };

  const getAllFAQs = async () => {
    let faqs = await getFaqs();
    setAllFaqs(faqs.data.FAQ);
  }

  const validateToken = async () => {
    try {
      await validateAccessToken();
      const userDetails = sessionStorage.getItem('UsersDetails');
      if (userDetails) {
        const user = JSON.parse(userDetails);
        if (adminList.includes(user.ccoid)) {
          setIsAdmin(true);
        }
        // const shepherdElement = document.querySelector("shepherd-native-embed") as any;
        // if (shepherdElement) shepherdElement.render();
      }
    } catch (error) {
      setIsAdmin(false);
      //console.warn('Anauthorised ', error);
      sessionStorage.clear();
      window.location.href = `${apiUrl()}okta/login`;
    }
  };

  const handleClickEvent = async (action: any) => {
    setIsEditing(!isEditing);

    if (action === 'Save') {
      const payload = {
        resources: isResourcesModified ? columns : [],
        faq: updatedFaqs
      }
      //call update api
      const data = await update_resources_faq(payload);
      if (data && data.status === 200) {
        getResourcesValue();
        getAllFAQs();
      }
    } else if (action === 'Cancel') {
      getResourcesValue();
      getAllFAQs();
    }
  }

  const handleQuestions = (e: any) => {
    setUpdatedFaqs(e);
  }

  return (
    <>
      <Helmet>
        <title>Web Help Portal</title>
      </Helmet>
      <div className='container' id='container'>
        <div className='row serachClass' id='SearchDiv'>
          <Search></Search>
        </div>
        {isAdmin && (
          isEditing ?
            <div className='row muse-mt-100 cmp-title--right mr-9'>
              <button className='muse-button-secondary muse-button-secondary--small muse-mr-30 cursor-pointer' onClick={() => handleClickEvent('Cancel')}> Cancel </button>
              <button className='muse-button-primary muse-button-primary--small cursor-pointer' onClick={() => handleClickEvent('Save')}> Save </button>
            </div>
            :
            <div className='row muse-mt-100 cmp-title--right mr-9'>
              <button className='muse-button-primary muse-button-primary--small cursor-pointer' onClick={() => handleClickEvent('Edit')}> Edit </button>
            </div>
        )}
        {loading ? (
          <div
            className='spinnerText'
            style={{
              marginTop: '50px',
            }}
          >
            <div _ngcontent-c3='' className='spinner'></div>
          </div>
        ) :
          <div
            className='row resourceClass muse-mt-50'
            id='resourceDiv'
          >
            {isEditing ? <ResourceEditView columns={columns} setColumns={setColumns} setIsResourcesModified={setIsResourcesModified} /> : <Resource columns={columns} />}
          </div>
        }
        <div className='row faqMainDiv' id='faqDiv'>

          {isAdmin && isEditing ?

            <div className='mainFaqEditSection'>
              {allFaqs && allFaqs.length > 0 ?
                <FaqEdit updatedQuestions={handleQuestions} details={allFaqs}></FaqEdit> : ''
              }
            </div>

            :
            <div>
              <FaqSection></FaqSection>
            </div>
          }

        </div>
      </div>
    </>
  );
}
