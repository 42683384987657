import { useEffect, useState } from 'react';
import Accordion from '../Accordion/Accordion';
import './faq.css';
import {
  apiUrl,
  getFaqs,
  validateAccessToken,
} from '../../services/commonService';
import { getPopularFaqs } from '../../services/commonService';
import { useLocation } from "react-router-dom"
import { Helmet } from 'react-helmet';

export default function FaqDetails() {
  const [faqData, setFaqData] = useState<any>([]);
  const [popfaqData, setPopFaqData] = useState<any>([]);

  let [loading, setLoading] = useState(true);

  const location = useLocation()
  const dtFromSearchBox = location.state?.idFromSearch

  useEffect(() => {
    window.scrollTo(0, 0);
    const getData = async () => {
      setLoading(loading);
      try {
        const faqData = await getFaqs();
        setFaqData(faqData.data.FAQ);
        setLoading(!loading);
      } catch (error) {
        //console.error('Failed to fetch faq data:', error);
      }
    };
    getData();
    setTimeout(() => validateToken(), 1000);
  }, []);

  const validateToken = async () => {
    try {
      await validateAccessToken();
    } catch (error) {
      //console.warn('Anauthorised ', error);
      sessionStorage.clear();
      window.location.href = `${apiUrl()}okta/login`;
    }
  };

  //get Popular Faq Data
  useEffect(() => {
    const getPopularData = async () => {
      try {
        const popularFaqData = await getPopularFaqs();
        if (popularFaqData.data.msg) {
          //console.error('Failed to fetch faq data:', popularFaqData.data.msg);
        } else {
          setPopFaqData(popularFaqData.data.FAQ);
        }
      } catch (error) {
        //console.error('Failed to fetch faq data:', error);
      }
    };
    getPopularData();
  }, []);
  return (
    <>
      <Helmet>
        <title>Web Help Portal</title>
      </Helmet>
      <div className='faqSectionDivs'>
        <div className='row cards muse-mt-140'></div>
        <div className='row faqTitle'>
          <h1 className='textAlignLeft resoureceDivLabel'>
            Frequently Asked Questions
          </h1>
        </div>

        <div className='muse-divider'></div>

        {loading ? (
          <div
            className='spinnerText'
            style={{
              marginTop: '140px',
              marginBottom: '80px',
              position: 'absolute',
              left: '50%',
            }}
          >
            <div _ngcontent-c3='' className='spinner'></div>
          </div>
        ) : (
          <div id='main-enquiry'>
            <div className='row cards muse-mt-140'></div>
            <div className='cds-l-grid cds-l-grid--twothirds-third padding-0'>
              <div className='cmp-containergrid__col1 cds-component-area faqBorder'>
                <Accordion details={faqData} dtFromSearch={dtFromSearchBox}/>
              </div>
              <div className='cmp-containergrid__col1 cds-component-area popularQuestions'>
                <h2 className='most-popular-question'>
                  <b>Most Popular Questions</b>
                </h2>
                <Accordion details={popfaqData} />
              </div>
            </div>
          </div>
        )}
      </div>
      <div className='row cards muse-mt-140'></div>
    </>
  );
}
